import React, { useState } from "react";
import PropTypes from "prop-types";
import PostListItem from "../components/PostListItem";
import { Button } from "react-bootstrap";

const PostList = ({ ghostPosts, markdownPosts }) => {
    const [visiblePosts, setVisiblePosts] = useState(4);

    const loadMorePosts = () => {
        setVisiblePosts((prevVisiblePosts) => prevVisiblePosts + 4);
    };
    return (
        <>
            {ghostPosts.length > 0 &&
                ghostPosts
                    .slice(0, visiblePosts)
                    .map(({ node }) => (
                        <PostListItem key={node.id} post={node} />
                    ))}

            {markdownPosts.length > 0 &&
                markdownPosts
                    .slice(0, visiblePosts)
                    .map(({ node }) => (
                        <PostListItem
                            key={node.id}
                            post={{ ...node.frontmatter, fields: node.fields }}
                            featureImage={
                                node.frontmatter.feature_image.publicURL
                            }
                        />
                    ))}
            <div className={`d-flex justify-content-center`}>
                <Button
                    variant={`primary`}
                    size={`lg`}
                    className={`px-4 mt-4`}
                    onClick={loadMorePosts}
                >
                    Load More
                </Button>
            </div>
        </>
    );
};

PostList.propTypes = {
    ghostPosts: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                id: PropTypes.string,
            }),
        })
    ),
    markdownPosts: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
                id: PropTypes.string,
                frontmatter: PropTypes.shape({
                    feature_image: PropTypes.shape({
                        publicURL: PropTypes.string,
                    }),
                }),
            }),
        })
    ),
};

export default PostList;
