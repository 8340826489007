import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import arrowIcon from "../images/icons/long-arrow-alt-right-solid-red.svg";
import PostTagDate from "../components/PostTagDate";
import * as style from "../styles/post-list-item.module.scss";

const PostListItem = ({ post, featureImage }) => {
    const url = post.fields.path;

    return (
        <Link to={url} className="">
            <div className={`${style.clarsyntPostListItem}`}>
                <div className={`${style.clarsyntPostListItemImage}`}>
                    {post.feature_image && (
                        <img
                            src={featureImage || post.feature_image}
                            alt={post.title}
                        />
                    )}
                    <div>
                        <span>Read Article</span>
                    </div>
                </div>
                <div className={`${style.clarsyntPostListItemContent}`}>
                    <h4>{post.title}</h4>
                    <PostTagDate post={post} />
                    <div className={`${style.clarsyntPostListItemContentText}`}>
                        {post.excerpt}
                    </div>
                    <div
                        className={`${style.clarsyntPostListItemContentReadMore}`}
                    >
                        Read more
                        <span>
                            <img src={arrowIcon} alt={`an arrow icon`} />
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    );
};

PostListItem.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        fields: PropTypes.object.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        published_at_pretty: PropTypes.string,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
    }).isRequired,
    featureImage: PropTypes.string,
};

export default PostListItem;
