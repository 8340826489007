import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Layout } from "../components/common";
import { MetaData } from "../components/common/meta";
import * as style from "../styles/one-pager.module.scss";
import PostList from "../components/PostList";

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */

const Tag = ({ data, location }) => {
    const tag = data.siteTag;
    const posts = data.allGhostPost.edges;
    const caseStudyPosts = data.allMarkdownRemark.edges;

    const HeaderContent = () => (
        <div>
            <div className={`${style.categoryHeaderIcon}`}>
                <img src={tag.og_image} alt={`Icon for ${tag.name}`} />
            </div>
            <h1 className={`${style.categoryTitle}`}>{tag.name}</h1>
        </div>
    );

    return (
        <>
            <MetaData data={data} location={location} type="series" />
            <Layout
                tag
                headerImage={tag.feature_image}
                headerCategory={<HeaderContent />}
            >
                <div className="container py-5">
                    <section>
                        <PostList
                            ghostPosts={posts}
                            markdownPosts={caseStudyPosts}
                        />
                    </section>
                </div>
            </Layout>
        </>
    );
};

Tag.propTypes = {
    data: PropTypes.shape({
        siteTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
            og_image: PropTypes.string,
            feature_image: PropTypes.string,
            slug: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.shape({
                node: PropTypes.shape({
                    id: PropTypes.string,
                    frontmatter: PropTypes.shape({
                        slug: PropTypes.string,
                        title: PropTypes.string,
                        excerpt: PropTypes.string,
                        feature_image: PropTypes.string,
                        tags: PropTypes.arrayOf(
                            PropTypes.shape({
                                name: PropTypes.string,
                                slug: PropTypes.string,
                            })
                        ),
                    }),
                }),
            }),
        }),
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Tag;
//language=GraphQl
export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        siteTag(slug: { eq: $slug }) {
            slug
            meta_title
            meta_description
            feature_image
            name
            og_image
            url
            postCount
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] }
            filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
        allMarkdownRemark(
            filter: {
                frontmatter: { tags: { elemMatch: { slug: { eq: $slug } } } }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        slug
                        title
                        excerpt
                        tags {
                            name
                            slug
                        }
                        feature_image {
                            publicURL
                        }
                    }
                    fields {
                        path
                    }
                }
            }
        }
    }
`;
